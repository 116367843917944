<template>
	<div>
		<!-- <BaseEmpty width="97"> -->
		<BaseEmpty>
			<template>
				<div class="filter-wrap mb-1">
					<div
						v-if="possuiPermissao('GER_I_RENDAS') || possuiPermissao('GER_U_RENDAS')"
						class="filter mr-2 icon-add-transaction"
						@click="showTransaction({type: 'renda'})"
						title="Lançar Renda"
					>
						<font-awesome-icon icon="money-check-dollar" class="fa-xl"/>
					</div>
					<div
						v-if="possuiPermissao('GER_I_TRANSFER') || possuiPermissao('GER_U_TRANSFER')"
						class="filter mr-2 icon-add-transaction"
						@click="showTransaction({type: 'transfer'})"
						title="Lançar Transferência"
					>
						<font-awesome-icon icon="money-bill-transfer" class="fa-xl"/>
					</div>
					<div
						v-if="possuiPermissao('GER_D_RENDAS') || possuiPermissao('GER_D_TRANSFER')"
						class="filter mr-2 icon-delete"
						v-bind:class="!itemSelected ? 'filter-disable' : ''"
						@click="confirmaApagar('All')"
						title="Apagar Selecionados"
					>
						<Trash2Icon/>
					</div>
					<div
						class="filter"
						v-bind:class="[showFiltersForm ? 'filter-active' : '']"
						@click="filterDropDownActive"
						title="Alterar Filtros"
					>
						<FilterIcon/>
					</div>
				</div>

				<b-modal
					ref="modalTransaction"
					hide-footer
					:title="titleModalTransaction"
					size="md"
					no-overflow
					hide-no-focus="true"
				>
					<div class="modal-edicao mb-3 modal-edicao-label">
						<b-row >
							<b-col cols="6" class="text-parcela">
								<label class="label-conta mb-0">Data Lançamento</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder=""
										id="data-lancamento"
										input-class="bg-white"
										v-model="incomeUpsert_date"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="Data Lançamento"
										:disabled="editing"
									/>
								</div>
							</b-col>
							<b-col cols="6" class="d-block text-parcela">
								<span class="label-conta">Valor</span>
								<b-form-input
									name="valor"
									class="modal-edicao-input number-class"
									v-model="incomeUpsert_amount"
									autocomplete="off"
									placeholder=""
									inputmode="numeric"
									v-validate="'required|noZeroCurrency'"
									:state="validateState('valor')"
									:disabled="editing"
									@input="updateValue(incomeUpsert_amount)"
								/>
							</b-col>
						</b-row>
						<b-row v-if="isTransfer">
							<b-col cols="12" class="text-parcela mb-2">
								<span class="label-conta">
									Conta Origem
								</span>
								<div>
									<b-form-select
										class="modal-edicao-input"
										v-model="transferUpsert_idAccountExpense"
										name="accountExpense"
										:options="optionModalAccount.filter(account => account.permitePagamento)"
										:disabled="editing || loading"
										v-validate="{ required: true }"
										:state="validateState('accountExpense')"
										@input="validateAccount('expense')"
									>
										<template slot="first">
											<option :value="null">
												Selecione
											</option>
										</template>
									</b-form-select>
								</div>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12" class="text-parcela">
								<span class="label-conta">
									{{ titleConta }}
								</span>
								<div>
									<b-form-select
										class="modal-edicao-input"
										v-model="incomeUpsert_idAccountIncome"
										name="accountIncome"
										:options="optionModalAccount.filter(account => account.permitePagamento)"
										:disabled="editing || loading"
										v-validate="{ required: true }"
										:state="validateState('accountIncome')"
										@change="validateAccount('income')"
									>
										<template slot="first">
											<option :value="null">
												Selecione
											</option>
										</template>
									</b-form-select>
								</div>
							</b-col>
						</b-row>
						<b-row v-if="haveCategory" class="mt-2">
							<b-col cols="12" class="text-parcela">
								<span class="label-conta">
									Categoria da Transação
								</span>
								<div>
									<b-form-select
										class="modal-edicao-input"
										v-model="incomeUpsert_idCategory"
										name="categoryIncome"
										:options="optionModalCategory"
										:disabled="loading"
										v-validate="{ required: true }"
										:state="validateState('categoryIncome')"
									>
										<template slot="first">
											<option :value="null">
												Selecione
											</option>
										</template>
									</b-form-select>
								</div>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col cols="12" class="d-block text-parcela">
								<span class="label-conta">Observações</span>
								<b-form-input
									name="comments"
									class="modal-edicao-input"
									v-model="incomeUpsert_comments"
									autocomplete="off"
									placeholder=""
									v-validate="{ required: false }"
									:state="validateState('comments')"
								/>
							</b-col>
						</b-row>
					</div>
					<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalTransaction')">Cancelar</b-button>
					<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
					<b-button
						class="mt-3 ml-1 float-right btn-modal btn-salvar"
						@click="save"
						:disabled="invalidForm"
					>
						Salvar
					</b-button>
				</b-modal>

				<div v-if="showFiltersForm" class="filters-form content-card">
					<div>
						<b-row
							class="filters-orientation filters-section"
						>
							<b-col class="filter-item">
								<label>Contas</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="loading"
									v-model="selectedAccount"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: true, labelName: 'nome' }"
									:selectOptions="selectAccount"
									data-cy="account"
									:btnLabel="() => (selectedAccount.length > 1 ? `${selectedAccount[0].nome}...` : selectedAccount[0] && selectedAccount[0].nome) || 'Selecione'"
								/>
							</b-col>
							<b-col class="filter-item">
								<label>Tipo de Transação</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="loading"
									v-model="selectedIipoTransacao"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: true, labelName: 'nome' }"
									:selectOptions="tipoTransacaoOptions"
									data-cy="account"
									:btnLabel="() => (selectedIipoTransacao.length > 1 ? `${selectedIipoTransacao[0].nome}...` : selectedIipoTransacao[0] && selectedIipoTransacao[0].nome) || 'Selecione'"
								/>
							</b-col>
							<b-col class="filter-item">
								<label>Categoria das Transações</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="loading"
									v-model="selectedCategory"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: true, labelName: 'text' }"
									:selectOptions="selectCategory"
									data-cy="account"
									:btnLabel="() => (selectedCategory.length > 1 ? `${selectedCategory[0].text}...` : selectedCategory[0] && selectedCategory[0].text) || 'Selecione'"
								/>
							</b-col>
						</b-row>
						<b-row
							class="filters-orientation filters-section-seconds"
						>
						<b-col class="filter-item">
								<label>Data inicial</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder="Início"
										id="data-inicial-historica"
										input-class="bg-white"
										v-model="inicio"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="Data Inicial"
										@input="setFilterDateEnd(inicio)"
									/>
								</div>
							</b-col>
							<b-col class="filter-item">
								<label>Data final</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder="Fim"
										id="data-final-historica"
										input-class="bg-white"
										v-model="fim"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="Data Final"
									/>
								</div>
							</b-col>
							<b-col class="filter-item">
								<label>
									Observações
								</label>
								<input
									id="comments"
									class="form-control time-pick"
									v-model="comments"
								/>
							</b-col>
						</b-row>
						<div class="mt-4 search-button" @click="findTransactions(true)">
							<SearchIcon/>
						</div>
					</div>
				</div>
				<b-row class="row-1">
				</b-row>
			</template>
		</BaseEmpty>
		<Base style="margin-top: -10px">
			<template>
				<div v-if="linhas.length" class="head-row mb-2 width-pagination-items mt-1">
					<div class="text-head-row width-pagination">
						<label class="text-head-row ml-0">
							Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + linhas.length }} de {{ pagination.count }}
						</label>
						<b-pagination
							class="mr-0 mb-2 ml-3"
							id="page"
							v-model="currentPage"
							:total-rows="pagination.count"
							:per-page="pagination.limit.value"
						></b-pagination>
					</div>
					<b-form
						class="text-head-row width-pagination">
						<label class="text-head-row ml-0 w-60">
							Itens por página
						</label>
						<b-form-select
							class="mr-0 mb-2"
							v-model="pagination.limit.value"
							name="itens-por-pagina"
							:options="optionsItensPorPag"
							@input="changeItensByPage()"
							:disabled="loading"
						/>
					</b-form>
				</div>
				<div v-if="linhas.length" class="table-responsive">
					<DataTable v-if="!isMobile"
						class="data-table"
						:loading="loading"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:nosearch="true"
						:nofilters="true"
						name="transacoes"
						:hasPagination="true"
						:noedit="true"
						:acoes="true"
						:permissaoEdit="possuiPermissao('GER_U_RENDAS')"
						:permissaoDelete="possuiPermissao('GER_D_RENDAS')"
						@clickDelete="confirmaApagar"
						@clickEdit="showTransaction"
						:selectItem="possuiPermissao('GER_M_AGENDAMENTOS')"
						:alignColumns="alignColumnsTable"
						@clickSortTableBy="(v) => sortTableBy(v)"
						:state="{
							sortBy: sortTable.sortBy,
							sortAsc: sortTable.order === 1,
							query: ''
						}"
						:async="true"
						@clickCheck="showButonsTop"
					/>
					<CardTransaction v-else
						:loading="loading"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:acoes="true"
						:permissaoEdit="possuiPermissao('GER_U_RENDAS')"
						:permissaoDelete="possuiPermissao('GER_D_RENDAS')"
						@clickDelete="confirmaApagar"
						@clickEdit="showTransaction"
						:selectItem="possuiPermissao('GER_M_AGENDAMENTOS')"
						:globalAcoes="false"
						@clickCheck="showButonsTop"
						:colsDataCard="colsDataCard"
					/>
				</div>
				<div v-else class="alert alert-warning" role="alert">
					{{ errMsg }}
				</div>
			</template>
		</Base>
	</div>
</template>

<script>
	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";
	import Datepicker from "vuejs-datepicker";
	import { ptBR } from "vuejs-datepicker/dist/locale";

	import BaseEmpty from "@/templates/BaseEmpty";
	import Base from "@/templates/Base.vue";
	import DataTable from "@/components/DataTable";
	import { getDateMonthEnd, numberFormatted, stringToNumber, stringToDateUTC, validateNumber, formatterSimple } from "@/helpers/common";
	import { AccountService } from "../../services/account";
	import { TransactionService } from "../../services/transaction";
	import { possuiPermissao } from "../../helpers/permissions";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import { CategoryExpensesService } from "../../services/categoryExpenses";
	import CardTransaction from "@/components/CardTransaction.vue";

	import "./styles.css";

	export default {
		components: {
			BaseEmpty,
			Base,
			DataTable,
			Datepicker,
			CardTransaction
		},

		inject: ["parentValidator"],

		data () {
			return {
				ready: true,
				loading: false,
				updating: false,
				ptBR,
				titleModalTransaction: "",

				optionModalAccount: [],
				selectAccount: [],
				tipoTransacaoOptions: [
					{
						nome: "Rendimentos",
						value: "INCOME"
					},
					{
						nome: "Despesas",
						value: "EXPENSES"

					},
					{
						nome: "Transferências",
						value: "TRANSFER"

					}
				],

				transactionsType: {
					INCOME: "Renda",
					EXPENSES: "Despesa",
					TRANSFER: "Transferência"
				},

				// filters
				selectedAccount: [],
				inicio: null,
				fim: null,
				comments: "",
				selectedIipoTransacao: [],
				selectCategory: [],

				transactions: [],
				showFiltersForm: false,
				showAddModal: false,
				selectTransaction: [],

				transactionSelecionada: {},
				optionModalCategory: [],

				incomeUpsert_comments: "",
				incomeUpsert_idAccountIncome: null,
				incomeUpsert_idCategory: null,
				incomeUpsert_date: new Date(),
				incomeUpsert_amount: "0,00",
				income_value: 0,
				transferUpsert_idAccountExpense: null,
				isTransfer: false,
				titleConta: "",
				isIncome: false,
				haveCategory: false,
				selectedCategory: [],

				errMsg: "Nenhuma transação cadastrada!",
				colunas: [
					"Conta",
					"Categoria",
					"Data",
					"Valor",
					"Tipo",
					"Observações"
				],
				alignColumnsTable: [
					"left",
					"left",
					"left",
					"right",
					"left",
					"left"
				],
				colsDataCard: {
					0: {
						idx: 0,
						label: "Conta",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					1: {
						idx: 1,
						label: "Categoria",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					2: {
						idx: 2,
						label: "Data",
						textAlign: "center",
						cols: 4,
						visible: true
					},
					3: {
						idx: 3,
						label: "Valor",
						textAlign: "right",
						cols: 4,
						visible: true
					},
					4: {
						idx: 4,
						label: "Tipo",
						textAlign: "left",
						cols: 4,
						visible: true
					},
					5: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					},
					6: {
						idx: 5,
						label: "Observações",
						textAlign: "left",
						cols: 12,
						visible: true
					},
					7: {
						idx: 7,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					}

				},
				linhas: [],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],

				invalidForm: true,
				editing: false,
				permitePagamento: null,
				selectItem: false,

				rendaExecuteDate: new Date(),
				rendaExecuteAccount: null,
				rendaExecuteAmount: numberFormatted(0),
				rendaExecuteComments: "",

				accountService: new AccountService(),
				transactionService: new TransactionService(),
				categoryService: new CategoryExpensesService(),
				possuiPermissao,
				sortTable: {
					sortBy: 2,
					order: -1
				},
				itemSelected: false,
				isMobile: false
			};
		},

		watch: {
			currentPage () {
				this.changePage();
			},

			filters () {
				this.linhas = [];
			}
		},

		async mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);

			await Promise.all([
				this.setFilter(),
				this.findAccount(),
				this.findCategory()
			]);
			await this.findTransactions();
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
			},

			async findCategory () {
				const result = await this.categoryService.listCategoryExpenses({offset: 0, limit: null}) || {};
				this.selectCategory = result.rows?.reduce((acc, item) => {
					// if (item.income)
					acc.push({ text: item.description, value: item.idCategoryExpense });

					return acc;
				}, []);

				this.optionModalCategory = result.rows?.reduce((acc, item) => {
					if (item.income)
						acc.push({ text: item.description, value: item.idCategoryExpense });

					return acc;
				}, []);
			},

			validateAccount (select) {
				if (this.transferUpsert_idAccountExpense === this.incomeUpsert_idAccountIncome)
					if (select === "expense")
						this.transferUpsert_idAccountExpense = null;
					else
						this.incomeUpsert_idAccountIncome = null;
			},

			sortTableBy (v) {
				this.sortTable = v;
				this.findTransactions();
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findTransactions();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findTransactions();
			},

			limparModal () {
				this.incomeUpsert_idTransaction = null;
				this.incomeUpsert_comments = "";
				this.incomeUpsert_idAccountIncome = null;
				this.incomeUpsert_idCategory = null;
				this.incomeUpsert_date = new Date();
				this.incomeUpsert_amount = "0,00";
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
				this.limparModal();
			},

			setFilter () {
				const fim = new Date();
				const month = fim.getMonth();
				const year = fim.getFullYear();
				const ultimoDiaMes = new Date(year, month + 1, 0).getDate();
				fim.setDate(ultimoDiaMes);
				const inicio = new Date();
				inicio.setDate("1");
				this.inicio = inicio;
				this.fim = fim;
			},

			setFilterDateEnd (date) {
				this.fim = getDateMonthEnd(date);
			},

			async findAccount () {
				const result = await this.accountService.listAccount({offset: 0, limit: null}) || {};
				this.selectAccount = result.rows?.reduce((acc, item) => {
					if (item.accountClass.automaticPay)
						return [ ...acc, { nome: item.description, value: item.idAccount }];

					return acc;
				}, []);

				this.optionModalAccount = result.rows?.map(item => ({
					text: item.description,
					value: item.idAccount,
					permitePagamento: item.accountClass.automaticPay
				})) || [];
			},

			async findTransactions (newFind) {
				if (newFind) {
					const exit = this.currentPage !== 1;
					this.pagination.page = 1;
					this.currentPage = 1;
					if (exit) return;
				}

				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;

				const filters = {
					limit,
					offset,
					initDate: dayjs(this.inicio).locale("pt-br").format("YYYY-MM-DD"),
					endDate: dayjs(this.fim).locale("pt-br").format("YYYY-MM-DD"),
					idAccount: this.selectedAccount,
					comments: this.comments,
					transactionType: this.selectedIipoTransacao.map(tipo => tipo.value),
					idsCategoryIncome: this.selectedCategory,
					sortTable
				};

				this.loading = true;
				const result = await this.transactionService.getTransactionsByParams(filters) || {};
				this.transactions = result.rows;
				this.linhas = result.rows.reduce((acc, c) => [
					...acc,
					{
						idTransaction: c.idTransaction,
						selected: false,
						cols: [
							c.expenseAccount.idAccount && c.incomeAccount.idAccount ? `${c.expenseAccount.description} ==> ${c.incomeAccount.description}` : c.incomeAccount.description || c.expenseAccount.description || "",
							c.categoryName ? c.categoryName : c.expenseAccount.idAccount ? "Pagamento" : "Recebimento",
							formatterSimple(c.date),
							numberFormatted(c.amount),
							this.transactionsType[c.transactionType] || "",
							c.comments
						]
					}
				], []);

				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
				if (this.showFiltersForm)
					this.showFiltersForm = false;
			},

			formatter (date) {
				return dayjs(date).locale("pt-br").format("D [de] MMMM [de] YYYY");
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			},

			async showTransaction (item_) {
				let { type } = item_;
				let item = {};
				this.haveCategory = true;
				if (item_.idTransaction) {
					[ item ] = this.transactions.filter(c => c.idTransaction === item_.idTransaction);
					type = item.idExpenseAccount && item.idIncomeAccount ? "transfer" : "renda";
					this.haveCategory = Boolean(item.categoryName);
				}

				const {
					amount,
					comments,
					date,
					idIncomeAccount,
					idExpenseAccount,
					idTransaction,
					idCategoryIncome
				} = item;

				this.titleConta = type === "transfer" ? "Conta Destino" : "Conta";
				this.isTransfer = Boolean(type === "transfer");
				this.isIncome = !this.isTransfer;

				if (!idTransaction) {
					this.titleModalTransaction = type === "transfer" ? "Executar Transferência" : "Adicionar Renda";
					this.editing = false;
				} else {
					this.titleModalTransaction = `Editar ${this.transactionsType[item.transactionType]}`;
					this.editing = true;
					this.isIncome = Boolean(item.transactionType === "INCOME");

					this.incomeUpsert_idTransaction = idTransaction;
					this.incomeUpsert_comments = comments;
					this.incomeUpsert_date = stringToDateUTC(date);
					this.incomeUpsert_amount = amount;
					this.incomeUpsert_idCategory = idCategoryIncome || "";
					if (type === "transfer") {
						this.incomeUpsert_idAccountIncome = idIncomeAccount || "";
						this.transferUpsert_idAccountExpense = idExpenseAccount || "";
					} else {
						this.incomeUpsert_idAccountIncome = idIncomeAccount || idExpenseAccount || "";
					}
				}
				this.invalidForm = true;
				this.$refs.modalTransaction.show();
			},

			async save () {
				const payload = {
					comments: this.incomeUpsert_comments,
					idIncomeAccount: this.incomeUpsert_idAccountIncome,
					idCategoryIncome: this.incomeUpsert_idCategory,
					date: this.incomeUpsert_date,
					amount: this.incomeUpsert_amount.replace(",", "."),
					idTransaction: this.incomeUpsert_idTransaction,
					transactionType: "INCOME"
				};

				if (this.isTransfer) {
					payload.transactionType = "TRANSFER";
					payload.idExpenseAccount = this.transferUpsert_idAccountExpense;
				}

				if (!payload.idIncomeAccount || !payload.amount) {
					const messagem = "Verifique se os seguintes campos estão preenchidos corretamente: ";
					let messagem_1 = !payload.idIncomeAccount ? "- Conta. " : "";
					messagem_1 = messagem_1.concat(!payload.amount ? "- Valor da renda. " : "");

					await this.$swal({
						title: "Salvar",
						text: messagem.concat(messagem_1),
						type: "error",
						showCancelButton: false,
						//imageWidth: 50,
						//imageHeight: 50,
						confirmButtonColor: "rgb(132, 170, 194)",
						confirmButtonText: "Ok"
					});
					this.invalidForm = true;
					return;
				}

				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						if (payload.idTransaction)
							await this.transactionService.updateTransaction({payload});
						else
							await this.transactionService.createTransaction({payload});

						this.fecharModal("modalTransaction");
						this.findTransactions();
						this.limparModal();

						return {
							title: "Sucesso!",
							body: "Transação salva com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar a Transação!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			async confirmaApagar (item_) {
				if (typeof (item_) === "string") {
					this.apagarVarios();
					return;
				}

				const [ item ] = this.transactions.filter((gu) => gu.idTransaction === item_.idTransaction);

				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja deletar a transação?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idTransaction);
			},

			async apagar (idTransaction) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						const payload = { idsTransaction: [idTransaction] };
						await this.transactionService.deleteTransaction({payload});
						this.findTransactions();

						return {
							title: "Sucesso!",
							body: "Transação deletada com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar a Transação!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			filterDropDownActive () {
				this.showFiltersForm = !this.showFiltersForm;
			},

			AddDropDownActive () {
				this.showAddModal = !this.showAddModal;
			},

			async apagarVarios () {
				const idsTransaction = this.linhas.reduce((acc, item) => {
					if (item.selected)
						return [ ...acc, item.idTransaction ];

					return acc;
				}, []);

				if (!idsTransaction.length) return;

				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja deletar a transação selecionada?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (!deleteConfirmation)
					return;

				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						const payload = { idsTransaction };
						await this.transactionService.deleteTransaction({payload});
						this.findTransactions();

						return {
							title: "Sucesso!",
							body: "transação deletada com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar a Transação!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			async updateValue (value) {
				this.incomeUpsert_amount = await validateNumber(value);
			},

			showButonsTop () {
				this.itemSelected = this.linhas.some(linha => linha.selected);
			}
		}
	};
</script>
